import { HttpUtil, Inject } from '@cds/common';
import { Message } from 'element-ui';
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { endsWith, startsWith } from 'lodash';
import { I18nService } from '@cds/i18n';
const ERROR = 'error';
// const SUCCESS = 'SUCCESS';
export const TOKEN = 'token';
// const directOutputUrls = ['/api/admin/export', '/api/user/export'];
export interface IResultData {
  code: number;
  data: any;
  message: string;
}
export enum Mode {
  development = 'development',
  production = 'production'
}

export enum ResultStatus {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info'
}
export class HttpService extends HttpUtil {
  @Inject(I18nService) private i18nSvc!: I18nService;
  private pmode: Mode = Mode.production;
  constructor() {
    super();
    this.defaultConfig.headers.common.Authorization = this.cache.get(TOKEN);
  }
  get token(): string {
    return this.cache.get(TOKEN);
  }

  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  // tslint:disable-next-line: adjacent-overload-signatures
  set token(val: string) {
    this.defaultConfig.headers.common.Authorization = val;
    this.cache.set(TOKEN, val);
  }

  set mode(val: Mode) {
    this.pmode = val;
  }

  set defaultConf(val: AxiosRequestConfig) {
    this.defaultConfig = val;
  }

  get defaultConf(): AxiosRequestConfig {
    return this.defaultConfig;
  }

  public async downloadFile(
    url: string,
    param: Dict<string>,
    filename?: string,
  ): Promise<void> {
    const resType: AxiosRequestConfig = { responseType: 'blob' };
    const blob = await this.get(url, param, resType);
    this.triggerDownload(blob, url, filename);
  }

  public async downloadFiles(
    url: string,
    param: Dict<string>,
    data: Dict<any>,
    filename?: string,
  ): Promise<void> {
    const resType: AxiosRequestConfig = { responseType: 'blob' };
    const blob = await this.post(url, data, param, resType);
    this.triggerDownload(blob, url, filename);
  }

  public triggerDownload(resData: Blob, url?: string, filename?: string): void {
    const fileName =
      filename || (url && url.split('/')[url.split('/').length - 1]) || 'doc';
    const link = document.createElement('a');
    link.target = '_blank';
    link.style.display = 'none';
    link.href = URL.createObjectURL(resData);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  protected async resultFilter(
    result: Promise<AxiosResponse<IResultData>>
  ): Promise<any> {
    try {
      const res = await result;
      const resultData: any = res.data;
      const dataHeader: any = resultData && resultData.header;
      const dataBody: any = resultData && resultData.body;
      if (this.getStatus(res.status) === ResultStatus.ERROR) {
        this.catchResponseError(
          resultData.code,
          JSON.stringify(resultData.message)
        );
      }
      if (res.config.responseType === 'blob') {
        return resultData;
      } else if (
        endsWith(res.config.url, '.json') ||
        this.getStatus(dataHeader.code, dataHeader.message) === ResultStatus.SUCCESS
      ) {
        return dataBody;
      } else {
        this.catchResponseError(
          dataHeader.code,
          JSON.stringify(dataHeader.message)
        );
      }
    } catch (err) {
      if (err.response) {
        this.catchResponseError(
          err.response && err.response.status,
          JSON.stringify(err.response.data)
        );
      } else {
        // throw new Error(err);
      }
    }
  }

  private catchResponseError(statusCode: number, data: string): void {
    switch (this.pmode) {
      case Mode.development:
        Message({
          message: data,
          type: ERROR
        });
        throw new Error(data);
      case Mode.production:
        if ([401, 403].includes(statusCode)) {
          this.cache.remove(TOKEN);
          location.href = process.env.VUE_APP_LOGIN_URL;
        } else if (statusCode==504) {
          Message({
            message: "程序处理中",
            type: ERROR
          });
          throw new Error("程序处理中");
        }else {
          console.log('catchResponseError 147else')
          Message({
            message: data,
            type: ERROR
          });
          throw new Error(data);
        }
        break;
    }
  }

  private getStatus(code: number, headerMessage?: string): ResultStatus {
    const codeStr = String(code);
    if ((startsWith(codeStr, '2') && codeStr.length === 3) || code === 2009 || code === 201 ) {
      return ResultStatus.SUCCESS;
    }
    else {
      let message: string = '';
      console.log('code service 161', code)
      switch (code) {
        case 301002:
          message = this.i18n.channel_mgmt.sync_success_301002;
          Message({
            message,
            type: ResultStatus.WARNING
          });
          break;
        case 40001:
          message = this.i18n.channel_mgmt.sync_success_301002;
          Message({
            message,
            type: ResultStatus.WARNING
          });
          break;
        case 2004:
          message = this.i18n.message.channel_agent_exist;
          Message({
            message,
            type: ResultStatus.WARNING
          });
          break;
        case 2003:
          message = this.i18n.message.channel_exist;
          Message({
            message,
            type: ResultStatus.WARNING
          });
          break;
        case 2002:
          message = this.i18n.message.manager_exist;
          Message({
            message,
            type: ResultStatus.WARNING
          });
          break;
        case 2005:
          message = this.i18n.user_list.email_exist;
          Message({
            message,
            type: ResultStatus.WARNING
          });
          break;
        case 2006:
          message = this.i18n.user_list.id_521_exist;
          Message({
            message,
            type: ResultStatus.WARNING
          });
          break;
        case 2008:
          // message = this.i18n.user_list.id_521_exist;
          Message({
            message: '上传文件模板错误',
            type: ResultStatus.ERROR
          });
          break;
        // case 2009:
        //   // message = this.i18n.user_list.id_521_exist;
        //   Message({
        //     message: '上传文件失败',
        //     type: ResultStatus.ERROR
        //   });
        //   break;
        case 300:
          // message = this.i18n.user_list.id_521_exist;
          Message({
            message: '文件已被删除',
            type: ResultStatus.ERROR
          });
          break;
        default:
          if (headerMessage) {
            if (headerMessage.indexOf('81013') != -1) {
              Message({
                message: '发送失败，请查看具体日志。',
                type: ResultStatus.ERROR
              });
            } else if (headerMessage.indexOf('82001') != -1) {
              Message({
                message: '发送失败，请检查信息。',
                type: ResultStatus.ERROR
              });
            } else{
              Message({
                message: headerMessage,
                type: ResultStatus.WARNING
              });
            }
          }
      }
      throw new Error(message || headerMessage);
    }
  }
}
