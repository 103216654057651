/**
 * @Author          : SuXiao xiao.su_ext@novartis.com
 * @Date            : 2024-10-09 18:43:02
 * @LastEditors     : SuXiao xiao.su_ext@novartis.com
 * @LastEditTime    : 2025-03-03 09:35:34
 * @FilePath        : \wecom-front-end-admin-portal\src\router\nuoxiaoer.router.ts
 * @Description     : [诺小二AI] 相关路由
 * @
 */
import { Role } from '@/model/schema';

const routes = [

  {
    path: '/documentManagement',
    name: 'nuoxiaoerDocumentList',
    component: () => import('../views/nuoxiaoerAI/ArticleList.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '手册-文档管理',
      titleEn: 'documentManagement'
    }
  },
  {
    path: '/addDocument',
    name: 'nuoxiaoerDocumentEdit',
    component: () => import('../views/nuoxiaoerAI/ArticleAdd.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '手册-文章配置',
      titleEn: 'addDocument'
    }
  },
  {
    path: '/nuoxiaoer',
    name: 'nuoxiaoer',
    component: () => import('../views/nuoxiaoerAI/NuoxiaoerIndex.vue'),
    meta: {
      roles: [],
      auth: false,
      title: '诺小二AI',
      titleEn: 'nuoxiaoer'
    }
  },
  {
    path: '/nuoxiaoer-keyword',
    name: 'nuoxiaoerKeyword',
    component: () => import('../views/nuoxiaoerAI/ArticleKeyword.vue'),
    meta: {
      roles: [],
      auth: false,
      title: '手册-文章关键词',
      titleEn: 'wenku-article-keyword'
    }
  },
  // {
  //   path: '/nuoxiaoer/edit',
  //   name: 'nuoxiaoerKeywordEdit',
  //   component: () => import('../views/nuoxiaoerAI/ArticleKeywordEdit.vue'),
  //   meta: {
  //     roles: [],
  //     auth: false,
  //     title: '文库-编辑文章关键词',
  //     titleEn: 'wenku-article-keyword-edit'
  //   }
  // },
  {
    path: '/nuoxiaoer-article-catelog',
    name: 'nuoxiaoerArticleCatelog',
    component: () => import('../views/nuoxiaoerAI/ArticleCatelog.vue'),
    meta: {
      roles: [],
      auth: false,
      title: '手册-文章目录页',
      titleEn: 'wenku-article-catelog'
    }
  },
  {
    path: '/nuoxiaoer-common-config',
    name: 'nuoxiaoerCommonConfig',
    component: () => import('../views/nuoxiaoerAI/CommonConfig.vue'),
    meta: {
      roles: [],
      auth: false,
      title: '通用配置项',
      titleEn: 'Common configurations'
    }
  },
  {
    path: '/nuoxiaoer-banner',
    name: 'nuoxiaoerBanner',
    component: () => import('../views/nuoxiaoerAI/WenkuBanner.vue'),
    meta: {
      roles: [],
      auth: false,
      title: '手册-banner',
      titleEn: 'wenku-banner'
    }
  },
  {
    path: '/nuoxiaoer-application',
    name: 'nuoxiaoerApplication',
    component: () => import('../views/nuoxiaoerAI/WenkuApplication.vue'),
    meta: {
      roles: [],
      auth: false,
      title: '手册-应用',
      titleEn: 'wenku-application'
    }
  },
  {
    path: '/nuoxiaoer-scenario',
    name: 'nuoxiaoerScenario',
    component: () => import('../views/nuoxiaoerAI/WenkuScenario.vue'),
    meta: {
      roles: [],
      auth: false,
      title: '手册-业务场景',
      titleEn: 'wenku-scenario'
    }
  },
  {
    path: '/nuoxiaoer-mp-video',
    name: 'nuoxiaoerMpVideo',
    component: () => import('../views/nuoxiaoerAI/MpVideo.vue'),
    meta: {
      roles: [],
      auth: false,
      title: '手册-诺华视界',
      titleEn: 'wenku-miniprogram-video'
    }
  },
  {
    path: '/nuoxiaoer-kpi',
    name: 'nuoxiaoerKPI',
    component: () => import('../views/nuoxiaoerAI/KpiAssessment.vue'),
    meta: {
      roles: [],
      auth: false,
      title: '一线KPI考核要求',
      titleEn: 'kpi Assessment'
    }
  },
  {
    path: '/nuoxiaoer-user-action',
    name: 'nuoxiaoerUserAction',
    component: () => import('../views/nuoxiaoerAI/UserAction.vue'),
    meta: {
      roles: [],
      auth: true,
      title: '用户行为日志',
      titleEn: 'user-action'
    }
  },

  // {
  //   path: '/nuoxiaoer/level1Tag',
  //   name: 'level1Tag',
  //   component: () => import('../views/nuoxiaoerAI/addDocument.vue'),
  //   meta: {
  //     roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
  //     auth: true,
  //     title: '一级标签管理',
  //     titleEn: 'addDocument'
  //   }
  // },
  // {
  //   path: '/nuoxiaoer/level2Tag',
  //   name: 'level2Tag',
  //   component: () => import('../views/nuoxiaoerAI/addDocument.vue'),
  //   meta: {
  //     roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
  //     auth: true,
  //     title: '二级标签管理',
  //     titleEn: 'addDocument'
  //   }
  // },
]

export default routes;
