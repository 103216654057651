import { MenuItem } from './models/menu.interface';
import { IPermissionItem } from '@/components/common/permission.interface';
import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import { find, some, map, includes, filter } from 'lodash';
import VueRouter from 'vue-router';
import { DirectiveBinding } from 'vue/types/options';
import { VNode } from 'vue/types/umd';
import { HttpService } from './http.service';
export class PermissionService {
  @Inject(I18nService) private i18nSvc!: I18nService;
  @Inject(HttpService) private http!: HttpService;
  private permissionList: IPermissionItem[] = [];
  private unauthPath: string = '/unauth';
  // 白名单
  private grantedPaths: Array<string | RegExp> = [
    // ---- 精确匹配项 ----
    this.unauthPath,
    '/home',
    '/addAward',
    '/detailsContent',
    '/addDetailsNewContent',
    '/detailsNewContent',
    '/addDetailsContent',
    '/addDocument',
    '/addSurvey',
    '/addProductMap',
    '/addImageText',
    '/imageTextClassify',
    '/greatGroup',
    '/creatRoster',
    '/creatMessage',
    '/channelRoster',
    '/channelMessage',
    '/advertApplication',
    '/nuoxiaoer',
    // ---- 正则匹配项 ----
    new RegExp('^/nuoxiaoer-'),
    new RegExp('^/nuoxiaoer/'), // 子路由为添加编辑等子页面
    //
    new RegExp('/wecomVideo/([a-zA-Z0-9]+)')
  ];
  // private menuApi: string =
  //   process.env.BASE_URL +
  //   `mock-menu-${this.i18nSvc.currentLocaleId || 'en-US'}.json`;

  public installRouter(router: VueRouter): void {
    const authPaths = filter(this.grantedPaths, (path) => {
      return typeof path === 'string';
    })
    const patternPaths = filter(this.grantedPaths, (path) => {
      return path instanceof RegExp;
    })
    console.log('[路由白名单] 精确匹配: ', authPaths);
    console.log('[路由白名单] 正则匹配: ', patternPaths);
    router.beforeEach(async (to, from, next) => {
      // console.log('[permission.service.ts]路由beforeEach 目标path', to.path);
      localStorage.setItem('previousRouteChanged', from.path);
      if (to.path !== from.path) {
        if (
          (await this.hasRouterPermission(to.path)) ||
          includes(authPaths, to.path) || some(patternPaths, (pattern: RegExp) => pattern.test(to.path))
        ) {
          next();
        } else {
          next({ path: this.unauthPath });
        }
      }
    });
  }

  public installDirective(Vue: any): void {
    Vue.directive(
      'permission',
      (el: HTMLElement, binding: DirectiveBinding, node: VNode) => {
        const permission = binding.value; // 获取到 v-permission的值 : privacy level
        if (!permission) {
          throw new Error('v-permission must be assigned by a value.');
        }
        if (!this.hasPermission(permission)) {
          // 没有权限 移除Dom元素
          if (el.parentNode) {
            el.parentNode.removeChild(el);
          }
          if (node.componentInstance) {
            node.componentInstance.$destroy();
            delete node.componentOptions;
          }
        }
      }
    );
  }

  public async findPermissionList(): Promise<IPermissionItem[]> {
    const i18n: Dict<any> = this.i18nSvc.i18nData;
    if (!this.permissionList || this.permissionList.length === 0) {
      //获取配置文件
      const res = await this.http.get('/api/application/system/setup');
      localStorage.setItem("setup",res.sales_division_name)
      // 调用后台接口获取全部的菜单栏数据
      const result: any[] = await this.http.get('/api/admin/permissions');
      if (result && Array.isArray(result)) {
        this.permissionList = map(result, (e) => {
          return {
            code: e.code,
            icon: e.menuIcon,
            id: Number.isFinite(e.id) ? e.id.toString() : e.id,
            name: this.i18nFormate(i18n, e.menuName) || e.name,
            parentId: Number.isFinite(e.parentId)
              ? e.parentId.toString()
              : 'ROOTPARENT',
            sort: Number.isFinite(e.menuOrder)
              ? e.menuOrder.toString()
              : e.menuOrder,
            type: e.type,
            url: e.path,
            applicationId: e.applicationId
          };
        });

        //  const peopleInformation = {
        //   applicationId: null,
        //   code: 'chatBook',
        //   icon: 'null',
        //   id: '1234567',
        //   name: '企微通讯录',
        //   parentId: '19',
        //   sort: 1,
        //   type: 'item',
        //   url: '/chatBook'
        // } as IPermissionItem;
        // this.permissionList.push(peopleInformation);

        // const peopleInformation = {
        //   applicationId: null,
        //   code: 'roleplay',
        //   icon: 'null',
        //   id: '1234567',
        //   name: 'Role Play',
        //   parentId: '19',
        //   sort: 1,
        //   type: 'item',
        //   url: '/roleplay'
        // } as IPermissionItem;
        // this.permissionList.push(peopleInformation);
        // 在后台返回的菜单数据里面添加一条通讯录的数据。这里是moke出来的数据，后期需要换为后台的。
      };
    };

    this.permissionList.push({
      id: 'ROOTPARENT',
      code: 'novartis-wecom-portal',
      name: '',
      url: '',
      parentId: '0',
      sort: 1,
      type: 'menuGroup',
      icon: ''
    });
    return this.permissionList;
  }

  private async hasRouterPermission(path: string): Promise<boolean> {
    const urlArr = path.replace(/^\//, '').replace(/\/$/, '').split('/');
    // console.log('[permission.service.ts]hasRouterPermission ', urlArr);
    if (!this.permissionList || this.permissionList.length === 0) {
      try {
        this.permissionList = await this.findPermissionList();
      } catch (e) {
        return false;
      }
    }
    const hasItem = this.permissionList.some(x => x.url === path);
    console.log(`[permission.service.ts]----路由全匹配 <${path}>是否存在：${hasItem}`);
    let res = false;
    for (const index in urlArr) {
      if (urlArr[index]) {
        const target = find(
          this.permissionList,
          (e) => e.url === `/${urlArr[index]}`
        ) as IPermissionItem;
        if (Number(index) === 0) {
          res = !!target;
          if (!res) {
            return Promise.resolve(res);
          }
        } else {
          res = !!target;
          if (res) {
            const previousTarget = find(
              this.permissionList,
              (e) => e.url === `/${urlArr[Number(index) - 1]}`
            ) as IPermissionItem;
            res = previousTarget.id === target.parentId;
          } else {
            return Promise.resolve(res);
          }
        }
      }
    }
    return Promise.resolve(res);
  }

  public hasPermission(key: string) {
    return some(this.permissionList, (e) => e.code === key);
  }

  private i18nFormate(i18n: any, value: string): string {
    if (!!value) {
      const temp: string[] = value.split('.');
      if (temp[0] && temp[1]) {
        return (i18n[temp[0]] && i18n[temp[0]][temp[1]]) || '';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
}
